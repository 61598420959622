export const Bio = {
  name: "Emrah Morgil",
  roles: [
    "Software Developer",
  ],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  github: "https://github.com/EmrahMorgil",
  linkedin: "https://www.linkedin.com/in/emrahmorgil/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React",
        image:
          "react.svg",
      },
      {
        name: "Redux",
        image:
          "redux.svg",
      },
      {
        name: "Next",
        image:
          "next.png",
      },
      {
        name: "HTML",
        image: "html.png",
      },
      {
        name: "CSS",
        image:
          "css.png",
      },
      {
        name: "JavaScript",
        image:
          "js.png",
      },
      {
        name: "TypeScript",
        image:
          "ts.svg",
      },
      {
        name: "Bootstrap",
        image:
          "bootstrap.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: ".Net Core Web API & .Net Core MVC",
        image: "dotnet.svg",
      },
      {
        name: "C#",
        image: "csharp.svg",
      },
      {
        name: "Node Js",
        image: "nodejs.svg",
      },
      {
        name: "Express Js",
        image: "expressjs.png",
      },
      {
        name: "Firebase",
        image: "firebase.svg",
      },
      {
        name: "MsSQL",
        image: "mssql.svg",
      },
      {
        name: "MongoDB",
        image: "mongodb.svg",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image: "git.svg",
      },
      {
        name: "GitHub",
        image: "github.png",
      },
      {
        name: "Docker",
        image: "docker.svg",
      },
      {
        name: "Postman",
        image: "postman.svg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "santsg.jpg",
    role: "Frontend Developer Intern",
    company: "San Tourism Software Group",
    date: "Feb 2023 - June 2023",
    desc: "I started my internship as a front-end developer in the B2C team. During the internship, I focused on learning relevant technologies, particularly React and Redux, and developed projects using these technologies",
    skills: [
      "Html",
      "Javacript",
      "Typescript",
      "React",
      "Redux",
      "Next",
    ],
  },
  {
    id: 1,
    img: "santsg.jpg",
    role: "Software Developer",
    company: "San Tourism Software Group",
    date: "June 2023 - May 2024",
    desc: "I have started working as a software developer in the B2C team. I am currently working on 2 projects, both of which are tourism websites. These websites also have a CMS panel. In the first project, my main focus is on front-end bug fixes. The technologies used in the first project are AngularJS and C# WebForms. In the second project, we are rebuilding the first project from scratch using Next.js and .NET Core technologies. In this project, I am working as a full-stack developer, implementing features.",
    skills: [
      "Html",
      "JavaScript",
      "TypeScript",
      "React",
      "Redux",
      "Next",
      "Docker",
      ".Net Core"
    ],
  },
  {
    id: 2,
    img: "mos.jpeg",
    role: "Software Developer",
    company: "MOS MARINE OTOMATION SYSTEM LTD.",
    date: "May 2024 - Present",
    desc: "I am working on software development and bug fixing using C# MVC and jQuery technologies in an ERP project developed for ship management at MOS Marine Automation Systems Company.",
    skills: [
      "Html",
      "Css",
      "Bootstrap",
      "JQuery",
      ".Net Core MVC",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "akdenizuni.jpeg",
    school: "Akdeniz University",
    date: "2019 - 2023",
    grade: "3.24 GANO",
    desc: "Throughout my university life, I have been involved in various projects. The area of Software Development that has captured my interest the most is Web Development. I have developed myself in this area and worked on projects.",
    degree: "Management Information System",
  }
];

export const projects = [
  {
    id: 0,
    title: "Simon Game",
    description:
      "A simon game I made when I was starting out in web development, along with a course I took on javascript. The game starts when a button is pressed. The game continues by following the squares with the mouse. The important point in the game is not to forget the order of the clicked squares.",
    image:
      "simongame.png",
    tags: [
      "Html",
      "Css",
      "JavaScript",
    ],
    github: "https://github.com/EmrahMorgil/Simon-Game",
    webapp: "https://simongame.emrahmorgil.com/",
  },
  {
    id: 1,
    title: "Chat App",
    description:
      "This project was developed as a full-stack application. It is an instant messaging application. Users can message each other in real-time after registering. This project is still being developed.",
    image:
      "chatapp.png",
    tags: [
      "Html",
      "Css",
      "JavaScript",
      "TypeScript",
      "React",
      "Redux",
      ".Net Core",
      "Docker",
      "Nginx",
      "Ubuntu"
    ],
    github: "https://github.com/EmrahMorgil/chatapp",
    webapp: "https://chatapp.emrahmorgil.com/",
  },
  {
    id: 1,
    title: "E - Commerce",
    description:
      "This project is one I developed during my internship. It features products on the homepage. Clicking on the 'Detail' button takes you to the product detail page. You can add products to your cart by clicking 'Buy Now.' There are Login and Register pages; after registering, you can place orders. Additionally, there is an admin panel for uploading, updating, and deleting products.",
    image:
      "ecommerce.png",
    tags: [
      "Html",
      "Css",
      "JavaScript",
      "TypeScript",
      "React",
      "Redux"
    ],
    github: "https://github.com/EmrahMorgil/E-Commerce-App-with-React-and-Redux",
    webapp: "https://ecommerce.emrahmorgil.com/",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
